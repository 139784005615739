import { Space, Select, Table, message, Spin, Tooltip, Button, Divider, Popconfirm, Modal, Typography, Checkbox, Row, Col, Card, Image, List } from 'antd';
import { DeleteOutlined, InfoCircleOutlined, BulbOutlined, UnorderedListOutlined, DollarOutlined, CheckOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import constants from '../../constants/constants';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
const { Option } = Select
const JobBoardBasketView = (props) => {

	//Variable declaration
	const [loader, setLoader] = useState(false);
	const [jobsCount, setJobsCount] = useState(0);
	const [priorityCheck, setPriorityCheck] = useState("f");
	const [jobBasketData, setJobBasketData] = useState([]);
	const [jobBasketSupplierList, setJobBasketSupplierList] = useState([]);
	const [buyerId,] = useState(Cookies.get(constants.COOKIE_SELECTED_BUYER_ID));
	const [showBoostProductBuy, setShowBoostProductBuy] = useState(false);
	const [termsChecked, setTermsChecked] = useState(false);
	// const [recommendTableShow,setRecommendTableShow]=useState(true);
	const [expandedRowKeys, setExpandedRowKeys] = useState([]);
	const [selectedJobList,setSelectedJobList] = useState([]);
	const [selectedTotal,setSelectedTotal]=useState(0);
	const [currencValue,setCurrencyValue]=useState();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [reload,setReload]=useState(false);
	const [visibleCounts, setVisibleCounts] = useState({}); // State to manage visible items
	const [supplierSelectedList,setSupplierSelectedList]=useState([]);

	// Table columns
	const boostJobsBuyColumns = [
		{
			title: 'Job',
			dataIndex: 'title',
			key: 'title',
			render: (text,record) =>
			(<>
				<p className='whitespace-nowrap'>{text}</p>
				<span className='font-semibold text-gray-400'>{record.name}</span></>
			)
		},
		{
			title: 'Location',
			dataIndex: 'place',
			key: 'place',
		},
		{
			title: 'Days',
			dataIndex: 'postingDurationDays',
			key: 'postingDurationDays',
			render: (text) =>
			(
				<span className='whitespace-nowrap'>{text} Days</span>
			)
		},
		{
			title: 'Price',
			dataIndex: 'price_net',
			key: 'price_net',
			render: (text,record) =>
			(
				<span className='whitespace-nowrap'>{record?.priceCurrency?.symbol} {text}</span>
			)
		},
		{
			title: 'Actions',
			key: 'action',
			align: 'center',
			render: (_, record) =>
				<Tooltip title="Delete" placement='bottom'>
					<Popconfirm title="Sure you want to delete?" onConfirm={()=>DeleteBuyProduct(record)} placement="left">
						<Button icon={<DeleteOutlined />} type="link" size='large' />
					</Popconfirm>
				</Tooltip>
		}
	];
	//On load function call
	useEffect(() => {
		getBuyerPrioritylist();
		jobBoardBoostsBasketList();
		jobBoardBoostsSupplierList();
	}, []);
	useEffect(() => {
		
	}, [reload]);
	//Functions
	//Function to get job bost basket listing.
	const jobBoardBoostsBasketList = async (recommend=true) => {
		setLoader(true);
		let url;
		let payload=null;
		if(recommend)
		{
			url = WebLinks.GetJobBoardBoostsViewRecommendListing ;
			payload={
				buyerId:buyerId
			}
		}else{
			url = WebLinks.GetJobBoardBoostsViewListing + buyerId;
		}
		const response = await APIUtils.apiCall(recommend?'POST':'GET', url,payload?JSON.stringify(payload):null);

		if (!response.isCatch && response.resultObj) {
			response.resultObj.data.map((item,index)=>{
				item.id=item.jobId+"j"+index
			})
			setJobBasketData(response.resultObj.data);
			setExpandedRowKeys(response.resultObj.data.map(item => item.id));
			setJobsCount(response.resultObj?.getCount);
			setLoader(false);
		} else {
			setJobBasketData([]);
			setJobsCount(0);
			message.error(response?.resultObj?.message);
			setLoader(false);
		}
	};
	const jobBoardBoostsSupplierList = async () => {
		// setLoader(true);
		let url;
		url = WebLinks.GetJobBoardBoostSupplierList + buyerId;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj) {
			setJobBasketSupplierList(response.resultObj.data);
		} else {
			setJobBasketSupplierList([])
			message.error(response?.resultObj?.message);
		}
		// setLoader(false);
	};
	//Function to get priority list
	const getBuyerPrioritylist = async () => {
		let url = WebLinks.GetBuyerPriorityFilters + buyerId;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj) {
			setPriorityCheck(response?.resultObj?.data?.length > 5 ? "t" : "f");
		} else {
			message.error(response?.resultObj?.message);
		}
	};
	// Table columns
	const yourJobsBasketColumns = [
		{
			title: 'id',
			dataIndex: 'id',
			key: 'id',
			hidden: true
		},
		{
			title: 'Priority',
			key: 'priority',
			dataIndex: 'priority',
			sorter: (a, b) => a.priority - b.priority,
			render: (_, record) => (
				<Select
					disabled={true}
					//onChange={(e) => singlePriorityChange(e, record.id)}
					value={record.priority}
					className='w-14 select-readonly'
					options={priorityCheck === "t" ? constants.PriorityConstantList2 : constants.PriorityConstantList1}
				/>
			),
		},
		{
			title: 'Job Title',
			dataIndex: 'title',
			key: 'jobTitle',
			sorter: (a, b) => a.title.localeCompare(b.title),
		},
		{
			title: 'Reference',
			dataIndex: 'reference',
			key: 'reference',
			sorter: (a, b) => {
				const referenceA = parseInt(a.reference.match(/\d+/)[0], 10);
				const referenceB = parseInt(b.reference.match(/\d+/)[0], 10);
				return referenceA - referenceB;
			},
		},
		{
			title: 'Location',
			dataIndex: 'place',
			key: 'place',
			sorter: (a, b) => a.place.localeCompare(b.place),
		},
		Table.SELECTION_COLUMN,
		{
			title: 'Select Products',
			key: 'action',
			align: 'center',
			render: (_, record) => (
				<Space>

				</Space>
			),
		},
		{
			title: 'Actions',
			key: 'action',
			align: 'center',
			render: (_, record) => (
				<Space>
					<Popconfirm
						title="Remove Job"
						description="Are you sure to remove this job from basket?"
						onConfirm={() => confirm(record.id)}
						onCancel={cancel}
						okText="Yes"
						cancelText="No"
					>
						<Tooltip title="Remove job from basket" zIndex='1000'>
							<Button size='small' icon={<DeleteOutlined />} type="link" danger />
						</Tooltip>
					</Popconfirm>
				</Space>
			),
		}
	];
	const cancel = (e) => {
	};

	//Function to delete job
	const confirm = async (jobId) => {
		const jobDeleteResponse = await APIUtils.apiCall("DELETE", WebLinks.DeleteJobBoardBasketJob + buyerId + "&jobId=" + jobId);
		if (!jobDeleteResponse.isCatch) {
			message.success(jobDeleteResponse.resultObj.message)
			jobBoardBoostsBasketList();
		} else {
			message.error(jobDeleteResponse.resultObj.message);
		}
	};

	//Book Jobs function
	const BookJobsNow=async()=>{
		let payload=null;
		payload={
			selectedJobList
		}
		const jobBookResponse = await APIUtils.apiCall("POST", WebLinks.AddSelectedRecommendedJobs+ buyerId ,JSON.stringify(selectedJobList));
		if (!jobBookResponse.isCatch) {
			message.success(
			<><p>Thanks for placing this order.</p>
			<p>We are now processing it.</p>
			<p>You can view the status of the order in the Boost Orders page.</p>
			<p>All jobs included in this order have been removed from your shopping basket.</p>
			<p>If you wish to Boost them to other boards you can add them back in and create an additional order.</p>
			</>
			)
			handleCancel();
			props.handleCancelParent();
		} else {
			message.error(jobBookResponse.resultObj.message);
		}
	}

	//Open  buy modal
	const openBuyModal = () => {
		setShowBoostProductBuy(true);
	}

	//Close buy modal
	const handleCancel = () => {
		setShowBoostProductBuy(false);
	};

	//Buy Products summary
	const BuyProductsSummary = () => {
		return (
			<>
				<Table.Summary.Row className='bg-teal-50'>
					<Table.Summary.Cell index={0}><strong>Total</strong></Table.Summary.Cell>
					<Table.Summary.Cell index={1} className='text-center'>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={2} className='text-center'>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={3}></Table.Summary.Cell>
					<Table.Summary.Cell index={4}>{currencValue} {selectedTotal}</Table.Summary.Cell>
				</Table.Summary.Row>
			</>
		)
	};

	//on Product selected
	const onProductSelected=(e,record,selectedrecord)=>{
		selectedrecord.checked=e.target.checked;
		setCurrencyValue(selectedrecord?.priceCurrency?.symbol)
		let tempSelectedArray=[];
		tempSelectedArray=selectedJobList;
		if(e.target.checked===true){
			let tempselectedObj=selectedrecord;
			tempselectedObj.title=record.title +"   "+record.reference;
			tempselectedObj.id=record.id;
			tempselectedObj.buyerId=buyerId;
			tempselectedObj.place=record.place;
			tempSelectedArray.push(tempselectedObj);
		}else{
			tempSelectedArray=tempSelectedArray.filter((item)=>!(item.key===e.target.value&& item.jobId===selectedrecord.jobId))
			let tempSelectedKeys=[];
			tempSelectedKeys=selectedRowKeys;
			tempSelectedKeys=tempSelectedKeys.filter((item)=>item!=record.id);
			setSelectedRowKeys(tempSelectedKeys);
		}
		let total=0;
		tempSelectedArray.map((item)=>{
			total=total+item?.price_net||0
		})
		setSelectedJobList(tempSelectedArray);
		setSelectedTotal(total);
	}

	//Expanded list
	const expandedRowRender = (record) => {
		const handleLoadMore = () => {
			setVisibleCounts((prev) => ({
				...prev,
				[record.id]: record?.jobRecommendations?.length, // Update only this row's count
			}));
		};

		const visibleCount = visibleCounts[record.id] || 5; // Default to showing 5 items

		const loadMore =
			record?.jobRecommendations?.length > visibleCount && (
				<div className="flex justify-end">
					<Space size="small">
						<span>{`${record.jobRecommendations.length} products`}</span>
						<Button type="link" onClick={handleLoadMore}>
							Show all
						</Button>
					</Space>
				</div>
			);

		return (
			<>
				<List
					grid={{ gutter: 8, column: 5 }}
					dataSource={record?.jobRecommendations?.slice(0, visibleCount) || []}
					loadMore={loadMore}
					renderItem={(item) => (
						<List.Item>
							<Card size="small" className="!w-full min-h-[150px]">
								<Checkbox
									value={item.key}
									checked={item.checked}
									className="w-full checkbox-top"
									onChange={(e) => onProductSelected(e, record, item)}
								>
									<Space className='items-start'>
										<Image src={item.logo} preview={false} width={100} />
										<Tooltip title={item.description}>
											<InfoCircleOutlined className='text-base' />
										</Tooltip>
									</Space>
									<div className='break-words my-3 text-xs'>
										{item.name + ' '}
									</div>
									<div className="flex justify-between items-center">
										{item.postingDurationDays + ' Days'}
										<Button size="small">
											{item?.priceCurrency?.symbol} {item.price_net}
										</Button>
									</div>
								</Checkbox>
							</Card>
						</List.Item>
					)}
				/>
				{!record?.jobRecommendations?.length && (
					<span className="font-bold">No product recommendations found.</span>
				)}
			</>
		);
	};

	//delete selected product
	const DeleteBuyProduct=(record)=>{
		let tempDeleteArray=selectedJobList;
		tempDeleteArray=tempDeleteArray.filter((item)=>!(item.key===record.key&&item.jobId===record.jobId))
		let total=0;
		tempDeleteArray.map((item)=>{
			total=total+item?.price_net||0
		})
		setSelectedJobList(tempDeleteArray);
		let tempJobData=jobBasketData;
		if(tempJobData?.length>0){
		let index = tempJobData.findIndex(item => item.id === record.id);
		 tempJobData[index]?.jobRecommendations?.map((recommendItem)=>{
				if(recommendItem.key===record.key){
				recommendItem.checked=false
				}
		})
		}
		setJobBasketData(tempJobData)
		setSelectedTotal(total);
	}

	const onSelectRow =(newSelectedRowKeys,selectedKey)=>{
		setSelectedRowKeys(newSelectedRowKeys);
	}

  const onSelectChange = (record,selected,selectedKey,nativekey) => {
		let tempSelectedData=[];
		tempSelectedData=selectedJobList;
		if(selected===true){
			selectedKey.map((itemAdd)=>{
				tempSelectedData=tempSelectedData.filter((item)=>!(item.jobId===itemAdd.jobId))
				itemAdd?.jobRecommendations?.map((itemobj)=>{
					itemobj.title=itemAdd.title  +"   "+itemAdd.reference;
					itemobj.id=itemAdd.id;
					itemobj.buyerId=buyerId;
					itemobj.place=itemAdd.place;
					setCurrencyValue(itemobj.priceCurrency?.symbol)
					tempSelectedData.push(itemobj);
			})})
		}else{
			tempSelectedData=tempSelectedData.filter((item)=>!(item.jobId===record.jobId))
		}

		let tempJobData=jobBasketData;
		if(tempJobData?.length>0){
		let index = tempJobData.findIndex(item => item.id === record.id);
		 tempJobData[index]?.jobRecommendations?.map((recommendItem)=>{
				recommendItem.checked=selected
		})
		}
		setJobBasketData(tempJobData)
		let total=0;
		tempSelectedData.map((item)=>{
			total=total+item?.price_net||0
		})
		setSelectedJobList(tempSelectedData);
		setSelectedTotal(total);
		setReload(!reload);
  }

	const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => onSelectChange(selectedRowKeys, selectedRows),
  };

	//Remove all selected products
	const removeSelectedAll=()=>{
		setSelectedJobList([]);
		jobBoardBoostsBasketList();
		handleCancel();
	}

	//on Supplir selected
	const onSupplierSeleted=(values)=>{
		setSupplierSelectedList(values)
	}

	return (
		<>
			<Spin spinning={loader}>
				<div>
					<div className="flex justify-end mb-3 text-xs">
						<Space wrap>
						</Space>
					</div>
					<div className="flex justify-between items-center gap-1">
						<Space>
							<div className="uppercase font-bold text-xl">Your Jobs Boost Basket</div>
							<span>{jobsCount} Jobs Listed </span>{props?.buyerId ? <span className='font-bold text'></span> : null}
						</Space>
					</div>
					<Divider className="my-3" />
					<div className="flex flex-wrap justify-between items-center gap-1 mb-3">
						<Space wrap>
							{/* <Button className={recommendTableShow?'warning-btn uppercase font-bold':' uppercase font-bold'} icon={<BulbOutlined />} onClick={()=>{setRecommendTableShow(true);jobBoardBoostsBasketList(true)}}>Recommend Products</Button>
							<span>OR</span> */}
							<Select
								placeholder="Select Job Board Supplier"
								className="w-full md:w-60"
								mode='muliple'
								onChange={(value)=>onSupplierSeleted(value)}
							>
								<Option value="-1">
									<Space>
										Show All
									</Space>
								</Option>
								{jobBasketSupplierList?.map((item => {
									return (
										<Option value={item.supplierId}>
											<Space>
												{item.name}
											</Space>
										</Option>
									)
								}))}
							</Select>
							<Button className='warning-btn uppercase font-bold' icon={<UnorderedListOutlined />} onClick={()=>{jobBoardBoostsBasketList()}}>Show Products</Button>
						</Space>
						<Button type="primary" disabled={selectedJobList?.length > 0 ? false : true} icon={<DollarOutlined />} onClick={() => openBuyModal()}>Buy selected products</Button>
					</div>
					<div className='overflow-x-auto'>
						{/* {recommendTableShow===false?
						<Table
							size='small'
							rowSelection={{
								// type: 'checkbox',
								// ...rowSelection,
								// selectedRowKeys: selectedRowKeys,
							}}
							pagination={false}
							//loading={loader}
							rowKey="jobId"
							columns={yourJobsBasketColumns}
							dataSource={jobBasketData}
						/>: */}
						<Table
							size='small'
							rowSelection={
								{
								type: 'checkbox',
								hideSelectAll:true,
								selectedRowKeys, // Bind selectedRowKeys to the state variable
								onSelect: onSelectChange, // Handle row selection/deselection
								onChange:onSelectRow,
								// ...rowSelection,
							}}
							rowKey="id"
							pagination={false}
							columns={yourJobsBasketColumns}
							dataSource={jobBasketData}
							className='hide-expand-cell'
						//	loading={loader}
							expandedRowKeys={expandedRowKeys}
							expandable={{
								defaultExpandAllRows: true,
								expandedRowRender,
								expandIcon: () => null,
								expandedRowClassName: 'bg-gray-100'
							}}
						/>
						{/* } */}
						<div className='text-right mt-3'>
							<Button type="primary" icon={<DollarOutlined />} disabled={selectedJobList?.length > 0 ? false : true} onClick={() => openBuyModal()}>Buy selected products</Button>
						</div>
					</div>
					<Modal
						title={"BOOST ORDER"}
						open={showBoostProductBuy}
						onCancel={handleCancel}
						style={{ top: 20 }}
						width={1000}
						footer={[]}
						destroyOnClose={true}
					>
						<div className='text-right mt-3'>
							<Button className='mr-3' icon={<DeleteOutlined />} onClick={() => removeSelectedAll()}>Remove All</Button>
						</div>
						<Table
							//loading={isLoading}
							size='small'
							columns={boostJobsBuyColumns}
							dataSource={selectedJobList}
							pagination={false}
							showHeader={false}
							summary={() => BuyProductsSummary()}
						/>
						<div className='mt-3'>
							<Checkbox onChange={(e) => { setTermsChecked(e.target.checked) }}> 
								{constants.BOOST_JOB_AD_TERMS_MSG}
								<Typography.Link onClick={() => window.open('/terms-and-conditions', '_blank')}>Terms and Conditions.</Typography.Link>
							</Checkbox>
						</div>
						<div className='font-semibold mt-3'>{constants.BOOST_JOB_AD_BUY_TEXT}</div>
						<div className='text-right mt-3'>
							<Button className='mr-3' icon={<ArrowLeftOutlined />} onClick={() => handleCancel()}>Change Selection</Button>
							<Button type="primary" disabled={!termsChecked } icon={<CheckOutlined />} onClick={()=>BookJobsNow()}>Book Now</Button>
						</div>
					</Modal>
				</div>
			</Spin>
		</>
	);
};

export default JobBoardBasketView;