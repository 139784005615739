import React, { useState } from "react";
import { Layout, Input, Form, Row, Col, Button, Image, Alert, Spin, Space, Typography, Divider } from 'antd';
import constants from '../../../constants/constants';
import logo from '../../../hire-logo-v2.svg';
import bgvideo from '../../../assets/headvid-reduced.mp4';
import APIUtils from '../../../api/APIUtils';
import WebLinks from '../../../api/WebLinks';
import { useAuth } from '../../../services/AuthService';
import Cookies from "js-cookie";
import { useHistory } from 'react-router-dom';

const { Header, Content } = Layout;


const Login = () => {
	const history = useHistory();
	const { loginAuth } = useAuth();

	const [isLoading,setIsLoading]=useState(false);
	const [alertMessage,setAlertMessage]=useState('');
    const [alertType,setalertType]=useState('');
    const [showAlert,setShowAlert]=useState(false);

	const showMessage = (message, type) => {
        setAlertMessage(message);
        setalertType(type);
        setShowAlert(true);
        // setTimeout(hideMessage, 2000);
    };
	
	const showErrorMessage = () =>{
		showMessage(constants.ERROR_MESSAGE,'error')
	};
	const onFinish = async (values) => {
        setIsLoading(true)
        let body=JSON.stringify({userName:values.email, password:values.password});
        let response=await APIUtils.apiCall("POST", WebLinks.Login, body, false);
		if(response.isCatch){
           //show error message
           showErrorMessage();
		   setIsLoading(false);
        } else {
            await onLoginAPISucess(response.resultObj)//,values.rememberMe)
        }
    };
	const onLoginAPISucess=(res)=>{
		if(res.statuscode===2000){
			loginAuth(res.data.accessToken, true).then( async () => {
				await getBuyerDetails(res.data);
				window.location.href = '/'
			});
		} else {
			showMessage(res && res.Message?res.Message:constants.ERROR_MESSAGE,'error');
			setIsLoading(false);
		}
    };
	const getBuyerDetails= async(data)=>{
		//set logged in and selected buyer cookies
		Cookies.set(constants.COOKIE_LOGGED_BUYER_ID,data.id,{path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
		Cookies.set(constants.COOKIE_LOGGED_BUYER_NAME,data.userName,{path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
		Cookies.set(constants.COOKIE_LOGGED_BUYER_SELF_POSTED,data.selfPosted,{path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
		Cookies.set(constants.COOKIE_SELECTED_BUYER_ID,data.id,{path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
		Cookies.set(constants.COOKIE_SELECTED_BUYER_NAME,data.userName,{path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
		Cookies.set(constants.COOKIE_IS_ADMIN,data.hireIntelligenceAdmin,{path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
		Cookies.set(constants.COOKIE_SELECTED_BUYER_SELF_POSTED,data.selfPosted,{path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
		Cookies.set(constants.COOKIE_EMAIL_POST,data.emailPost,{path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
		Cookies.set(constants.COOKIE_SELECTED_IS_SUB_BUYER_ADMIN,data.subBuyerAdmin,{path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
		Cookies.set(constants.COOKIE_LOGGEDIN_IS_SUB_BUYER_ADMIN,data.subBuyerAdmin,{path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
		Cookies.set(constants.COOKIE_IS_RECRUITER,data.recruiter,{path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
		setIsLoading(false)
	};
	return (
		<Spin spinning={isLoading}>
			<div className='bg-img'>
				<video loop autoPlay muted class="video-bg">
					<source src={bgvideo} type="video/mp4" />
					Your browser does not support HTML5 video.
				</video>
				<Layout className='h-screen'>
					<Header className='container mx-auto bg-transparent px-3 lg:px-10'>
						<Image src={logo} preview={false} width={255} className='mt-3' />
					</Header>
					<Content className='container mx-auto px-3 lg:px-10 flex items-center'>
						<Row className="w-full">
							<Col xs={24} lg={8}>
								<Form layout="vertical" requiredMark={false} onFinish={(e)=>onFinish(e)}>
								{showAlert?<Alert className="alert" message={alertMessage} type={alertType} closable  showIcon />:null}
									<Form.Item
										label={<span className='text-white uppercase'>{constants.Email}</span>}
										name="email"
										rules={[
											{
												type: 'email',
												required: true,
												message: constants.LoginEmailValid,
											},
										]}
									>
										<Input autoFocus size="large" placeholder={constants.Email} />
									</Form.Item>
									<Form.Item
										label={<span className='text-white uppercase'>{constants.Password}</span>}
										name="password"
										rules={[
											{
												required: true,
												message: constants.LoginPasswordValid,
											},
										]}
									>
										<Input.Password
											placeholder={constants.Password}
											size="large"
										/>
									</Form.Item>
									<Form.Item>
										<Button size="large" block htmlType="submit" className='warning-btn'>
											{constants.Login}
										</Button>
									</Form.Item>
								</Form>
							</Col>
							<Col xs={24} lg={24} className="absolute bottom-2.5 right-6">
								<Space split={<Divider type="vertical" className="!border-white float-right" />}>
									<Typography.Link className="!text-white hover:!text-yellow-200" onClick={()=>window.open('/data-policy','_blank')}>Data Policy</Typography.Link>
									<Typography.Link className="!text-white hover:!text-yellow-200" onClick={()=>window.open('/terms-and-conditions','_blank')}>Terms and Conditions</Typography.Link>
									<Typography.Link className="!text-white hover:!text-yellow-200" onClick={()=>window.open('/privacy-policy','_blank')}>Privacy Policy</Typography.Link>
									<Typography.Link className="!text-white hover:!text-yellow-200" onClick={()=>window.open('/cookie-policy','_blank')}>Cookie Policy</Typography.Link>
								</Space>
							</Col>
						</Row>
					</Content>
				</Layout>
			</div>
		</Spin>
	);
};

export default Login;
