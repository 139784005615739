import React from 'react';
import { Layout, Image, Menu, Space } from "antd";
import logo from '../../hire-logo-v2.svg';
import headgif from '../../head-gif.gif';
import { Link } from "react-router-dom";
import { useAuth } from '../../services/AuthService';
import Cookies from 'js-cookie';
import constants from '../../constants/constants';

const { Header } = Layout;

const Headers = () => {
	const { logoutAuth } = useAuth()
	return (
		<Header className='flex justify-between items-center bg-transparent container mx-auto 2xl:max-w-full 2xl:px-4 mt-3 px-3 lg:px-0 h-min' id='top'>
			<div className='flex items-center gap-4 logo ml-8 lg:ml-0'>
				<Image src={logo} preview={false} />
				{/* <Image src={headgif} preview={false} /> */}
			</div>
			<Space>
				<span className='text-sm text-white whitespace-nowrap absolute top-1 right-2 md:static px-1 bg-gray-500 md:bg-transparent'>{Cookies.get(constants.COOKIE_SELECTED_BUYER_NAME)} [{Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)}]</span>
				<Menu className='bg-transparent text-white text-lg font-semibold flex' mode="horizontal" disabledOverflow={true}>
					<Menu.Item key="profile">
						<Link to="/profile">
							Profile
						</Link>
					</Menu.Item>
					<Menu.Item key="logs">
						<Link to="/activity-logs">
							Logs
						</Link>
					</Menu.Item>
					<Menu.Item key="logout" onClick={() =>
						logoutAuth().then(() => {
							window.location.href = '/login'
						})}>
						Logout
					</Menu.Item>
				</Menu>
			</Space>
		</Header>
	);
};

export default Headers;
